import React from "react";
import Banner from "../Banner/banner"
import "./indexstyle.css"


function Home() {
  return (
    <div>
        
        <Banner />

        <footer >
            <p className="copyright">&copy; Anna Conover</p>
        </footer>
    </div>
  );
}

export default Home;
